const en = {
  dayOfMonthOrdinalParse: /\d{1,2}(st|nd|rd|th)/,
  ordinal(number) {
    const tens = Math.floor((number % 100) / 10);
    const ones = number % 10;
    let suffix = 'th';

    if (tens === 1) {
      suffix = 'th';
    } else if (ones === 1) {
      suffix = 'st';
    } else if (ones === 2) {
      suffix = 'nd';
    } else if (ones === 3) {
      suffix = 'rd';
    }

    return number + suffix;
  },
  week: {
    dow: 1, // Monday is the first day of the week.
  },
};

export default en;
